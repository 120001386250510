/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { searchProducts } from 'src/redux/slices/product';

export const useUpdateQueryParams = (filters, page = 1, showCategory) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Return a function that accepts shouldReplace
  return (shouldReplace = false) => {
    const queryParams = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        queryParams.set(key, filters[key]);
      }
    });

    queryParams.set('page', page);
    queryParams.set('limit', 20);

    navigate(`?${queryParams.toString()}`, { replace: true });

    dispatch(searchProducts({ queryParams, shouldReplace }));
  };
};

useUpdateQueryParams.propTypes = {
  filters: PropTypes.object,
  page: PropTypes.number,
};
