import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { LoadingScreen } from 'src/components/loading-screen';
//

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();

  const { isAuthenticated, isInitialized } = useSelector((state) => state.auth);

  const check = useCallback(() => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = paths.auth.login;

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    if (isInitialized) {
      check();
    }
  }, [isInitialized, check]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
