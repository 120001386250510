import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const router = useRouter();

  // const searchParams = useSearchParams();

  const returnTo = paths.root;

  const { isAuthenticated } = useSelector((state) => state.auth);

  const check = useCallback(() => {
    if (isAuthenticated) {
      router.replace(returnTo);
    }
  }, [isAuthenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
