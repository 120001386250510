import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// utils
import { axiosInstanceNew } from 'src/utils/axios';

// redux
import { initialized } from 'src/redux/slices/auth';

// ----------------------------------------------------------------------

export function AuthInitializer({ children }) {
  const dispatch = useDispatch();

  // verify accessToken JWT in de cookies with the backend
  const checkTokenValidity = async () => {
    try {
      const response = await axiosInstanceNew.get('api/client/user/verifytoken');
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const initialize = useCallback(async () => {
    const validUser = await checkTokenValidity();
    if (validUser !== null) {
      dispatch(
        initialized({
          isAuthenticated: true,
          isInitialized: true,
          user: validUser,
        })
      );
    } else {
      dispatch(
        initialized({
          isAuthenticated: false,
          isInitialized: true,
          user: null,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}

AuthInitializer.propTypes = {
  children: PropTypes.node,
};
