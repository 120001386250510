import axios from 'axios';
// config
import { HOST_API_DEV, DEV_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_DEV });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default { axiosInstance };

// ----------------------------------------------------------------------

const DEV = 'http://localhost:5050';
const PROD = 'https://api.haraash.com';

const baseUrl = DEV_API === 'development' ? DEV : PROD;

export const axiosInstanceNew = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  headers: {
    'x-app-origin': 'client',
    'Content-Type': 'application/json',
  },
});

export const setInterceptor = (store) => {
  axiosInstanceNew.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch({ type: 'auth/logout' });
      }
      return Promise.reject(error);
    }
  );
  return axiosInstanceNew;
};

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
