import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useScrollToTopClick(autoScroll = true) {
  const { pathname } = useLocation();

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (autoScroll) {
      scrollToTop();
    }
  }, [pathname, scrollToTop, autoScroll]);

  return scrollToTop;
}
