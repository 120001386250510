import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// utils
import { axiosInstanceNew } from '../../utils/axios';

// FETCH USER

export const fetchUser = createAsyncThunk('auth/fetch-user', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstanceNew.post('/api/client/user/fetch-user', data);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// FETCH USER PRODUCTS

export const fetchUserProducts = createAsyncThunk(
  'product/fetchUserProducts',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew(`/api/client/product/fetchUserProducts/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// FETCH USER PROFILE PRODUCTS

export const fetchUserProfileProducts = createAsyncThunk(
  'product/fetchUserProfileProducts',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew(
        `/api/client/product/fetchUserProducts/profile/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// EDIT USER PROFILE

export const editProfile = createAsyncThunk(
  'user/editModeratorProfile',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew.post('api/client/user/edit-user', data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew.delete(`/api/client/product/deleteProduct/${id}`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const deleteProducts = createAsyncThunk(
  'products/deleteProducts',
  async (products, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew.post(`/api/client/product/deleteproducts`, {
        products,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const initialState = {
  isLoading: false,
  displayedUser: null,
  userProducts: [],
  profileProducts: [],
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: () => initialState,
    // UPDATE DISPLAYED USER
    updateDisplayedUser(state, action) {
      state.isLoading = false;
      state.displayedUser = action.payload.user;
      state.profileProducts = state.profileProducts.map((product) =>
        product._id === action.payload.product._id ? action.payload.product : product
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.displayedUser = action.payload.user;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUserProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userProducts = action.payload.products;
      })
      .addCase(fetchUserProducts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUserProfileProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserProfileProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileProducts = action.payload.products;
      })
      .addCase(fetchUserProfileProducts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload.user;
        state.error = null;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileProducts = action.payload.products;
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileProducts = action.payload.products;
      })
      .addCase(deleteProducts.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default profileSlice.reducer;

export const { resetProfile, updateDisplayedUser } = profileSlice.actions;
