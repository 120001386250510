import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance, { axiosInstanceNew } from '../../utils/axios';

// LOGIN API REQUEST

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    // Input validation (basic example)
    if (!email || !password) {
      return rejectWithValue('Email and password are required');
    }

    try {
      const res = await axiosInstanceNew.post('/api/client/user/login', { email, password });

      if (!res.data) {
        throw new Error('Invalid response data');
      }
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// REGISTER API REQUEST

export const register = createAsyncThunk('auth/register', async (form, { rejectWithValue }) => {
  try {
    const res = await axiosInstanceNew.post('/api/client/user/register', form);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// LOGOUT

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    const { dispatch } = thunkAPI;
    const res = await axiosInstanceNew.post('/api/client/user/logout');
    if (res.status === '200') {
      dispatch(logoutStore());
    }
    return res.data;
  } catch (error) {
    const { rejectWithValue } = thunkAPI;
    return rejectWithValue(error.response.data);
  }
});

// SEND VERIFICATION EMAIL

export const sendVerificationEmail = createAsyncThunk(
  'user/sendVerificationEmail',
  async (email, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/user/verifyemail', email);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// FORGRET PASSWORD

export const submitForgetPassword = createAsyncThunk(
  'user/changePassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/user/forget-password', data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// VERIFY CODE USER

export const submitVerification = createAsyncThunk(
  'user/submitVerification',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/user/verify/submit', data);
      return res.data.payload;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// RESEND VERIFY CODE USER

export const resendVerificationCode = createAsyncThunk(
  'user/resendVerificationCode',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew(`/api/client/user/verify/resend/${userId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// RESEND VERIFY CODE USER USING THE EMAIL INSTEAD OF USER ID

export const resendEmailVerificationCode = createAsyncThunk(
  'user/resendEmailVerificationCode',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew.post(
        '/api/client/user/verify/resend-verification-code',
        data
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// RESEND VERIFY CODE USER FORGOT PASSWORD

export const resendForgotPasswordCode = createAsyncThunk(
  'user/resendForgotPasswordCode',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew(
        `/api/client/user/verify/forget-password/resend/${userId}`
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// CHECK IF USER IS VERIFIED

export const isVerfified = createAsyncThunk(
  'user/isVerfified',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew(`/api/client/user/verify/${userId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// CHECK IF USER IS VERIFIED

export const forgetPasswordGuard = createAsyncThunk(
  'user/isVerfified',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew(`/api/client/user/verify/check-code-expiry/${userId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// CHANGE PASSWORD

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/user/change-password', data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// SET PASSWORD

export const setPassword = createAsyncThunk(
  'user/setPassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post('/api/admin/moderator/setpassword', data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// LOGOUT

export const logoutStore = createAction('auth/logout');

export const resetPasswordGuard = createAsyncThunk(
  'user/emailVerificationCompleted',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance(`api/admin/moderator/resetpassword/guard/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isInitialized: false,
  currentUser: null,
  error: null,
  emailVerification: false,
  setPassword: false,
};

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialized(state, action) {
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        currentUser: action.payload.user,
      };
    },

    // UPDATE CURRENTUSER
    updateCurrentUser(state, action) {
      state.isLoading = false;
      state.currentUser = action.payload.user;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload.user;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.currentUser = null;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.currentUser = null;
        state.error = action.payload.error;
      });
  },
});

export const { initialized, updateCurrentUser } = userSlice.actions;

// Reducer
export default userSlice.reducer;
