import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import authReducer from './slices/auth';
import profileReducer from './slices/profile';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
  emailVerification: false,
  setPassword: false,
};

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    // eslint-disable-next-line no-unused-vars
    const { auth, ...restState } = state;
    return appReducer(
      {
        ...restState,
        auth: {
          ...initialState,
          isInitialized: true,
        },
      },
      action
    );
  }
  return appReducer(state, action);
};
export default rootReducer;
