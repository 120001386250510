import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// redux
import { useSelector } from 'react-redux';
// component
import Iconify from 'src/components/iconify';
//
import NavList from './nav-list';

// ----------------------------------------------------------------------

export default function NavDesktop({ offsetTop, data }) {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 2.5, height: 1 }}>
      {data.map((link) => {
        if (link.isButton) {
          return (
            isAuthenticated && (
              <Button
                key={link.title}
                variant="contained"
                color="primary"
                sx={{ alignSelf: 'center' }}
                endIcon={<Iconify icon="ic:baseline-plus" />}
                href={link.path}
              >
                {link.title}
              </Button>
            )
          );
        }

        return <NavList key={link.title} item={link} offsetTop={offsetTop} />;
      })}
    </Stack>
  );
}

NavDesktop.propTypes = {
  data: PropTypes.array,
  offsetTop: PropTypes.bool,
};
