import { logoutStore } from './slices/auth';

const logoutMiddleware = (store) => (next) => (action) => {
  if (action.type === 'auth/tokenExpired') {
    store.dispatch(logoutStore());
  }

  next(action);
};

export default logoutMiddleware;
