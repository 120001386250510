import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';

// routes
import { paths } from 'src/routes/paths';
//
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
  return (
    <Button
      component={RouterLink}
      href={paths.auth.login}
      variant="contained"
      sx={{ mr: 1, pl: 2, pr: 2, ...sx }}
      endIcon={<Iconify icon="ic:baseline-plus" />}
    >
      Sell
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
